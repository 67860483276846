import "./Footer.scss";

function FooterCol3() {
  return (
    <>
      <div className="footerCol3">
        <div className="table-col">
          <h3>Harga Minyak Dunia</h3>
          <table className="table">
            <tbody>
              <tr>
                <td>WTI Crude Oil</td>
                <td>88.75</td>
                <td className="color-style">-0.70%</td>
              </tr>
            </tbody>
          </table>
          <table className="table">
            <tbody>
              <tr>
                <td>Brent Crude Oil</td>
                <td>92.16</td>
                <td className="color-style">-0.24%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-col">
          <h3>Nilai Tukar Dollar</h3>
          <iframe
            src="https://id.widgets.investing.com/live-currency-cross-rates?theme=lightTheme&amp;pairs=1645,1898,2138"
            width="100%"
            height="275px"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default FooterCol3;
