import React, { useState } from "react";
import "./Home.scss";

import product0101 from "../assets/images/04/01-01.jpg";
import product0102 from "../assets/images/04/01-02.jpg";
import product0201 from "../assets/images/04/02-01.jpg";
import product0202 from "../assets/images/04/02-02.jpg";
import product0301 from "../assets/images/04/03-01.jpg";
import product0302 from "../assets/images/04/03-02.jpg";
import product0401 from "../assets/images/04/04-01.jpg";
import product0402 from "../assets/images/04/04-02.jpg";
import product0501 from "../assets/images/04/05-01.jpg";
import product0502 from "../assets/images/04/05-02.jpg";
import product0601 from "../assets/images/04/06-01.jpg";
import product0602 from "../assets/images/04/06-02.jpg";

const imageSources = [
  [product0101, product0102],
  [product0201, product0202],
  [product0301, product0302],
  [product0401, product0402],
  [product0501, product0502],
  [product0601, product0602],
];

function ImageCard({
  title,
  imagePair,
  isFlipped,
  setFlippedCard,
}: {
  title: string;
  imagePair: string[];
  isFlipped: boolean;
  setFlippedCard: () => void;
}) {
  const handleClick = () => {
    setFlippedCard();
    setFlippedCard();
  };

  const imageSrc = isFlipped ? imagePair[1] : imagePair[0];

  return (
    <div className="col-md-4">
      <div
        className={`image-container ${isFlipped ? "flipped" : ""}`}
        onClick={handleClick}
      >
        <img src={imageSrc} className="img-fluid" alt={imageSrc} />
      </div>
      <h4>{title}</h4>
    </div>
  );
}

function HomeContent1() {
  const [flippedCard, setFlippedCard] = useState<number | null>(null);

  const titles = [
    "PP Warna",
    "PP Hitam Copolymer",
    "PP PK",
    "ABS PK Kulkas",
    "HI PK Kulkas",
    "ABS Hitam Proses",
  ];

  return (
    <div className="home-content-1 text-center">
      <div className="container">
        <div className="row content">
          <h2>Biji Plastik Spesialisasi PT Cita Jaya Plasindo</h2>
          {imageSources.map((imagePair, index) => (
            <ImageCard
              key={index}
              title={titles[index]}
              imagePair={imagePair}
              isFlipped={index === flippedCard}
              setFlippedCard={() => setFlippedCard(index)}
            />
          ))}
          <p>
            Pengalaman puluhan tahun membuat kami mempunyai jaringan yang luas
            sehingga kami siap mendukung pasokan biji plastik yang dibutuhkan
            oleh proses produksi anda. Selain biji plastik di atas kami juga
            menyediakan beragam biji plastik lainnya seperti HDPE (High Density
            Polyethylene), AS (Acrylonitrile Styrene), PC (Poly Carbonate),
            Nylon/PA (Polyamide), Acrylic/PMMA (Polymethyl Methacrylate), POM
            (Polyoxymethylene, Polyacetal) dan lain lain.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomeContent1;
