import "./Home.scss";
import HomeBanner from "./HomeBanner";
import HomeContent1 from "./HomeContent1";
import HomeContent2 from "./HomeContent2";
import Features from "../Features/Features";
import ContactUs from "../Components/ContactUs/ContactUs";

function Home() {
  return (
    <div className="home">
      <HomeBanner></HomeBanner>
      <HomeContent1></HomeContent1>
      <HomeContent2></HomeContent2>
      <Features></Features>
      <ContactUs></ContactUs>
    </div>
  );
}

export default Home;
