import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function FooterCol2() {
  return (
    <>
      <div className="footerCol2">
        <h3>Artikel Berita Biji Plastik</h3>

        <div className="title-icon">
          <FontAwesomeIcon icon={faNewspaper} className="newspaper-icon" />
        </div>

        <div className="card">
          <div className="card-img"></div>
          <div className="card-body">
            <h5 className="card-title">
              OCT
              <br />
              17
            </h5>
            <p className="card-text">Perjalanan Plastik PP Dan Inovasinya.</p>
            <a href="#" className="btn btn-primary">
              <span>Read More</span>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="arrow-right-icon"
              />
            </a>
          </div>
        </div>
        <div className="card">
          <div className="card-img"></div>
          <div className="card-body">
            <h5 className="card-title">
              OCT
              <br />
              10
            </h5>
            <p className="card-text">
              Ini Dia Perbedaan Biji Plastik Original dengan Biji Plastik Daur
              Ulang.
            </p>
            <a href="#" className="btn btn-primary">
              <span>Read More</span>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="arrow-right-icon"
              />
            </a>
          </div>
        </div>
        <div className="card">
          <div className="card-img"></div>
          <div className="card-body">
            <h5 className="card-title">
              OCT
              <br />
              03
            </h5>
            <p className="card-text">
              Plastik PP - Solusi Ramah Lingkungan untuk Industri Modern.
            </p>
            <a href="#" className="btn btn-primary">
              <span>Read More</span>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="arrow-right-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterCol2;
