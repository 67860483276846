import FeatureCard, {
  FeatureCardProp,
} from "../Components/FeatureCard/FeatureCard";
import "./Features.scss";
import features from "./Features.json";

function Features() {
  return (
    <>
      <div className="features-container">
        <div className="container text-center">
          <h2>MENGAPA MEMILIH PT Cita Jaya Plasindo UNTUK MENJADI PARTNER ANDA</h2>
          <p>
            Selain pengalaman dan komitmen PT Cita Jaya Plasindo dalam menyediakan
            biji plastik yang berkualitas tinggi, kami juga memberikan jaminan
            dan pelayanan terbaik untuk anda. Sehingga anda tidak perlu ragu
            memilih kami sebagai partner yang berharga.
          </p>

          <div className="row align-items-start">
            {features.map((item: FeatureCardProp, index: number) => (
              <FeatureCard
                key={index}
                icon={item.icon}
                title={item.title}
                description={item.description}
              ></FeatureCard>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
