import "./ContactUs.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDollar } from "@fortawesome/free-solid-svg-icons";

function ContactUsbutton() {
  return (
    <a href="#">
      <FontAwesomeIcon icon={faCommentDollar} />
      <span>Hubungi Kami Sekarang</span>
    </a>
  );
}

export default ContactUsbutton;
