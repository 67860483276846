import "./../Global.scss";
import "./Footer.scss";
import FooterCol1 from "./FooterCol1";
import FooterCol2 from "./FooterCol2";
import FooterCol3 from "./FooterCol3";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <FooterCol1></FooterCol1>
            </div>
            <div className="col-lg-4">
              <FooterCol2></FooterCol2>
            </div>
            <div className="col-lg-4">
              <FooterCol3></FooterCol3>
            </div>
          </div>
        </div>
        <span className="copy-right">Copyright © 2023 PT Cita Jaya Plasindo</span>
      </div>
    </>
  );
}

export default Footer;
