import "./ContactUs.scss";
import ContactUsbutton from "./ContactUsButton";

function ContactUs() {
  return (
    <>
      <div className="contact-us-container">
        <div className="container">
          <div className="content">
            <h2>
              Hubungi Kami Untuk Biji Plastik
              <br />
              Berkualitas
            </h2>
          </div>
          <ContactUsbutton></ContactUsbutton>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
