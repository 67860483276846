import "./FeatureCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { faBusinessTime } from "@fortawesome/free-solid-svg-icons";
import priceReduce from "./../../assets/images/priceReduce.svg";
import gurantee from "./../../assets/images/gurantee.svg";

export interface FeatureCardProp {
  icon: string;
  title: string;
  description: string;
}

function FeatureCard({ icon, title, description }: FeatureCardProp) {
  return (
    <>
      <div className="col-lg-3">
        <div className="card">
          <div className="card-body">
            <div className="card-icon">
              {(() => {
                switch (icon) {
                  case "faHandshake":
                    return (
                      <FontAwesomeIcon
                        icon={faHandshake}
                        className="brand-icon"
                      />
                    );
                  case "faBusinessTime":
                    return (
                      <FontAwesomeIcon
                        icon={faBusinessTime}
                        className="brand-icon"
                      />
                    );
                  case "priceReduce":
                    return (
                      <img
                        src={priceReduce}
                        className="brand-icon"
                        alt={title}
                      />
                    );
                  case "guarantee":
                    return (
                      <img src={gurantee} className="brand-icon" alt={title} />
                    );
                }
              })()}
            </div>

            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeatureCard;
