import "./Home.scss";

function HomeContent2() {
  return (
    <div className="home-content-2">
      <div className="container">
        <div className="row content">
          <div className="col">
            <h2>
              Biji Plastik Daur Ulang
              <br />
              Berkualitas Tinggi
            </h2>
            <p>
              PT Cita Jaya Plasindo selalu berkomitmen membuat biji
              <br />
              plastik daur ulang yang terjamin kualitas dan
              <br />
              kebersihannya, sehingga biji plastik yang dihasilkan setara dengan
              biji plastik original.
            </p>
            <p>
              Ini dikarenakan bahan baku pembuatan biji plastik
              <br />
              diseleksi dengan ketat, bukan dari sampah melainkan sisa produksi
              dari pabrik plastik yang bersih dan tidak
              <br />
              tercampur dengan material lain
            </p>
            {/* <iframe
              src="https://www.youtube.com/embed/1NtaA9qUp_g?si=ihsA6BBHGnJMTsRL"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe> */}
          </div>
          <div className="col">
            <div className="content-box">
              <h3>1. Seleksi Ketat Bahan Baku</h3>
              <p>
                Dengan staff yang sudah berpengalaman, setiap bahan baku yang
                diterima akan kami seleksi dengan ketat. Ini adalah kunci untuk
                menghasilkan biji plastik yang berkualitas tinggi
              </p>
            </div>
            <div className="content-box">
              <h3>2. Pengolahan Bahan Baku</h3>
              <p>
                Bahan baku yang telah diseleksi akan kami olah menggunakan mesin
                yang berkualitas sehingga menghasilkan bentuk biji plastik yang
                seragam
              </p>
            </div>
            <div className="content-box">
              <h3>3. Pemeriksaan Hasil Produksi</h3>
              <p>
                Biji plastik yang sudah diolah akan melalui proses pemeriksaan
                bertahap untuk memastikan biji plastik yang dihasilkan ialah
                berkualitas tinggi
              </p>
            </div>
            <div className="content-box">
              <h3>4. Pengepakan dan penyimpanan</h3>
              <p>
                Biji plastik yang lolos pemeriksaan akan kami pak dan kami
                simpan untuk kemudian dikirim ke pabrik-pabrik plastik di
                seluruh indonesia
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeContent2;
