import "./Home.scss";
import ContactUsbutton from "../Components/ContactUs/ContactUsButton";
import React, { useState, useEffect } from "react";

function HomeBanner() {
  const words = ["BERGARANSI", "BERKUALITAS"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isActive1, setIsActive1] = useState(true);
  const [isActive2, setIsActive2] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);

      if (currentWordIndex === 0) {
        setIsActive1(true);
        setIsActive2(false);
      } else {
        setIsActive1(false);
        setIsActive2(true);
      }
    }, 3000);

    return () => clearInterval(timer);
  }, [currentWordIndex]);

  return (
    <div className="home-banner">
      <div className="container">
        <div className="row content">
          <div className="col">
            <h1>
              MENYEDIAKAN BIJI PLASTIK
              <br />
              <span
                className={`rotating-word ${isActive1 ? "active" : "inactive"}`}
              >
                {words[0]}
              </span>
              <span
                className={`rotating-word ${isActive2 ? "active" : "inactive"}`}
              >
                {words[1]}
              </span>
              SEJAK TAHUN 2000
            </h1>
            <h3>
              PT Cita Jaya Plasindo adalah supplier biji plastik / distributor
              biji plastik yang terpercaya sejak tahun 2000.
              <br />
              <br />
              Kami telah mensuplai lebih dari 500 pabrik plastik di seluruh
              Indonesia.
            </h3>
            <ContactUsbutton />
          </div>
          <div className="col" />
        </div>
      </div>
    </div>
  );
}

export default HomeBanner;
