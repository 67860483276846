import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactUsbutton from "../Components/ContactUs/ContactUsButton";
import "./Header.scss";
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

function HeaderTop() {
  return (
    <div className="header-top">
      <div className="container">
        <div className="row content">
          <div className="col">
            <ContactUsbutton></ContactUsbutton>
          </div>
          <div className="col">
            <div className="top-right">
              <a href="#">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href="#">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="#">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderTop;
