import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import ContactUsbutton from "../Components/ContactUs/ContactUsButton";

import PTlogo from "../assets/images/PTlogo.png";
import footerTitLine from "../assets/images/footerTitLine.png";

function FooterCol1() {

  return (
    <>
      <div className="footerCol1">
        <div className="footer-logo">
          <img src={PTlogo} className="img-fluid" alt="PT Logo" />
        </div>
        <h2>
          Hubungi Kami Untuk
          <br />
          Biji Plastik
          <span>
            Berkualitas
            <span className="footerTitLine">
              <img
                src={footerTitLine}
                className="img-fluid"
                alt="footerTitLine"
              />
            </span>
          </span>
        </h2>
        <p>
          Supplier biji plastik / distributor biji plastik yang terpercaya sejak
          tahun 2000. PT Cita Jaya Plasindo telah mensuplai lebih dari 500
          pabrik plastik di seluruh Indonesia.
        </p>
        <div className="footer-btn">
          <ContactUsbutton></ContactUsbutton>
        </div>
        <div className="company-detail">
          <ul>
            <li>
              kp sawah RT 01 RW 01.
              <br />
              Ragajaya, Bojonggede, Bogor. Jawa barat.
            </li>
            <li>
              No telp :
              <a
                href="tel:+085934362770"
                className="no-underline primary-color"
              >
                +08 593 436 2770
              </a>
            </li>
            <li>
              Email :{" "}
              <a
                href="citajaya.plasindo99@gmail.com"
                className="no-underline primary-color"
              >
                citajaya.plasindo99@gmail.com
              </a>
            </li>
          </ul>
          <div className="brand-icon">
            <div className="icon-brand-container facebook-bg-color">
              <FontAwesomeIcon icon={faFacebook} className="brand-icon" />
            </div>
            <div className="icon-brand-container instagram-bg-color">
              <FontAwesomeIcon icon={faInstagram} className="brand-icon" />
            </div>
            <div className="icon-brand-container youtube-bg-color">
              <FontAwesomeIcon icon={faYoutube} className="brand-icon" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterCol1;
